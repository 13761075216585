// import axios from "axios";

import { toast } from "react-toastify";
import { Axios } from "./axios.interceptor";
// import { useSelector } from "react-redux";

// const token =useSelector((state)=>state.user.user.token);

//******************GRaph data***************** */
export const barGraph = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/graphdata`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

export const notifiedToken = ({ token, Tok }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/notifytoken`,
        { notifytoken: Tok },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// ***************** LoginSIgnup************************************
// login

export const hanldeSignUp = ({ user, setError, role }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        "/api/v1/login",
        {
          email: user.email,
          password: user.password,
          role: role,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError(
          "An error occurred. Please check your network connection and try again."
        );
      }
      reject(error);
    }
  });
};

export const triggerBackgroundAuthentication = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/background-auth`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// otp page or verify page

export const handleOtp = ({ otp, userId, type }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/verify`,
      {
        otp,
        refId: userId,
        type: type,
      }
      // {
      //     headers:{
      //       'Authorization': `Bearer ${token}`,
      //     }
      // }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// forget pass api
export const forgetPassApi = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/forget-password`, {
      userinput: email,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// verifyapiotp
export const verifyApi = ({ refId, otp, type }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify`, {
      refId: refId,
      otp: otp,
      type: type,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// resentotp
export const resendOtpApi = ({ refId, type }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/resendOtp`, {
      refId: refId,
      type: type,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// reset pass api
export const resetPassApi = ({ newPassword, cPassword, token, type }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/reset-password`,
      {
        newPassword: newPassword,
        cPassword: cPassword,
        type: type,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// ***********notifcal bell icons***********************

export const handleNotificationApi = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// *****************Account Mangment*****************************

export const updateAccount = async ({ user, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("firstName", user.firstName);
      formData.append("lastName", user.lastName);
      formData.append("email", user.email);
      formData.append("phoneNo", user.phoneNo);
      formData.append("bio", user.bio);
      if (typeof user.profile == "object" && user.profile != "") {
        formData.append("profile", user.profile);
      }
      const response = await Axios.patch(`/api/v1/admin/account`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(response);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

export const updatePassword = async ({ allPass, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/change-password`,
        {
          currentpassword: allPass.currentPassword,
          newPassword: allPass.newPassword,
          cPassword: allPass.cPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// **********************content Management******************

// addFaq
export const addFaq = async ({ faq, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        "/api/v1/admin/faq",
        {
          Question: faq.question,
          Answer: faq.answer,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error creating new admin:", error);
      reject(error);
    }
  });
};

// get Faq
export const getFaq = async ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get("/api/v1/admin/faq", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error creating new admin:", error);
      reject(error);
    }
  });
};

// editFaq

export const editFaq = async ({ _id, Question, Answer, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/faq`,
        {
          _id,
          Question,
          Answer,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      reject(error);
      console.log(`Error editing FAQ: ${error.message}`);
    }
  });
};

// deletFaq
export const deletFaqs = async ({ id, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(`/api/v1/admin/faq`, {
        data: { _id: id },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
      console.log(`Error deleting user: ${error.message}`);
    }
  });
};

// get about us
export const getAboutUs = async ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/aboutsection`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

//  update aboutus
export const aboutUs = async ({ data, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/aboutsection`,
        { data: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// getTerm and condition
export const termAndCondition = async ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/termsconditions`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// update term nd condition'

export const updteTemsCondition = async ({ data, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/termsconditions`,
        { data: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// get privacy

export const getPrivacyPolicy = async ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/privacypolicy`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// Get Report Content
export const getReportContent = async ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

export const PatchReportContent = async ({ token, data }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/reports`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// update privacy
export const uptePrivacy = async ({ data, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/privacypolicy`,
        { data: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

//***********************************Paymnet COmmison Intergation***************************** */

// pay figure api
export const payfigureData = ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get("/api/v1/admin/paymentfigures", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error fetching payment figures:", error);
      reject(error);
    }
  });
};

// getpayment detials
export const getPaymentDetails = ({
  token,
  activePage,
  searchQuery,
  filter,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/payments?page=${activePage}&&search=${searchQuery}&filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error fetching payment data:", error);
      reject(error);
    }
  });
};

// settle at once

export const settleAtOnce = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/payoutatonce`,
        {},
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error fetching payment data:", error);
      reject(error);
    }
  });
};

// settle payment

export const settleBartenderPaymemt = ({ token, orderId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/payout`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error fetching payment data:", error);
      reject(error);
    }
  });
};

// *****************************BartenderAllIntegrations**************************

// admin managemnt fetch user
export const fetchUsers = ({ setError, token, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/admins?page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError(
          "An error occurred. Please check your network connection and try again."
        );
      }
      reject(error);
    }
  });
};

// admin managemnt create
export const handleCreateUser = async (
  newUser,
  setFirstName,
  setLastName,
  setEmail,
  setRole,
  setUsers,
  token
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post("/api/v1/admin/admins", newUser, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
      setUsers((prevUsers) => [...prevUsers, response.data]);
      setFirstName("");
      setLastName("");
      setEmail("");
      setRole(0);
    } catch (error) {
      console.error("Error creating new admin:", error);
      reject(error?.response?.data);
    }
  });
};

// admin managemnt delete
export const handleDeleteUser = ({ id, token }) => {
  return new Promise(async (resolve, reject) => {
    const config = {
      data: { uid: id },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    Axios.delete("/api/v1/admin/admins", config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        reject(error);
      });
  });
};

// admin managemnt updte user

export const handleUpdateUser = async ({ user, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("firstName", user.firstName);
      formData.append("lastName", user.lastName);
      formData.append("email", user.email);
      formData.append("role", user.role);
      formData.append("uid", user.uid);
      if (typeof user.profile == "object") {
        formData.append("profile", user.profile);
      }
      const response = await Axios.patch(`/api/v1/admin/admins`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// list of bartenders in BAROOOM app

export const allBartenderData = async ({
  activePage,
  token,
  setError,
  searchQuery,
  filter,
  startDate,
  endDate,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/allbartenders?page=${activePage}&&search=${searchQuery}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError(
          "An error occurred. Please check your network connection and try again."
        );
      }
      reject(error);
    }
  });
};

//crete baretedner
export const createBartenderDetails = async ({ token, userData }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const location = {
        state: userData.state,
        city: userData.city,
        postalCode: userData.zipCode,
      };
      const formData = new FormData();
      formData.append("fullName", userData.name);
      formData.append("email", userData.email);
      formData.append("phoneNo", userData.phoneNo);
      formData.append("dob", userData.dob);
      formData.append("location", JSON.stringify(location));
      formData.append("role", userData.role);
      // formData.append('')
      if (typeof userData.profile == "object" && userData.profile != "") {
        formData.append("profile", userData.profile);
      }
      const response = await Axios.post("/api/v1/admin/createuser", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(response);
    } catch (error) {
      console.error("Error creating bartender:", error);
      reject(error.response?.data);
    }
  });
};

// post id/  bartender detils with id

export const fetchParticularDetails = async ({ token, uid }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        "/api/v1/admin/viewBartender",
        { uid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// barten dletr user delet
export const handleDeletBartenderUser = async ({ uid, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(`/api/v1/admin/deleteUser`, {
        data: { uid: uid },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
      console.log(`Error deleting user: ${error.message}`);
    }
  });
};

// suspend user id

export const handleSuspendUser = async ({ uid, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/suspendUser`,
        { uid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response);
    } catch (error) {
      console.error("Error suspending user:", error);
      reject(error);
    }
  });
};

// resume bartender user
export const handleResumeUser = async ({ uid, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/resumeUser`,
        { uid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response);
    } catch (error) {
      console.error("Error resuming user:", error);
      reject(error);
    }
  });
};

// suspecious account or block
export const handleBlock = async ({ uid, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/blockUser`,
        { uid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response);
    } catch (error) {
      console.error("Error resuming user:", error);
      reject(error);
    }
  });
};

// bartemder details update
export const handleUpdateBartender = ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();

      const response = await Axios.patch(`/api/v1/admin/updatebartender`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

// total gigs
export const totlGigs = ({ uid, token, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/getgigs?page=${activePage}`,
        { uid },
        {
          headers: {
            " Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// reveiw

export const totlReview = ({ uid, token, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/totalreviews?page=${activePage}`,
        { uid },
        {
          headers: {
            " Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

//  delet review
export const deleteReview = ({ rid, token, type }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(`/api/v1/admin/totalreviwes`, {
        data: { rid },
        type: type,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      resolve(response.message);
    } catch (error) {
      console.log(`Error deleting Review: ${error.message}`);
      reject(error);
    }
  });
};

// referals
export const totlReferals = ({ uid, token, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/totalreferals?page=${activePage}`,
        { uid },
        {
          headers: {
            " Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// total searnings

export const totlEarnings = ({ uid, token, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/totalEarnings?page=${activePage}`,
        { uid },
        {
          headers: {
            " Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// bartender ticket
export const HandleTicketMang = ({ token, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/getBTickets?page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

export const GetTemplates = ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/notification-templates`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

export const PostTemplates = ({
  token,
  title,
  body,
  document,
  tempImg,
  _id,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append("body", body);
      if (tempImg) {
        formdata.append("tempImg", tempImg);
      }

      if (_id) {
        formdata.append("_id", _id);
      }
      formdata.append("document", document);
      const response = await Axios.patch(
        `/api/v1/admin/notification-templates`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

export const DeleteTemplates = ({ token, _id }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(
        `/api/v1/admin/notification-templates`,
        {
          data: { _id },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// get msg
export const ticketGetMsg = ({ chatId, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        "/api/v1/getmsg",
        { chatId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// resolve tickets
export const resolveTicket = ({ token, ticketid }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        "/api/v1/admin/ticket",
        { ticketid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// send msg
export const ticketSendMsg = ({ chatId, text, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        "/api/v1/sendmsg",
        {
          chatId: chatId,
          text: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// bartender report api
export const reportbar = ({ searchQuery, activePage, role, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/reports?page=${activePage}&&search=${searchQuery}`,
        { role },
        {
          headers: {
            " Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// ******************************************Establishmnet all integration*******************************

// fetch all establishment details in tables
export const fetchEstbDetails = ({
  searchQuery,
  activePage,
  token,
  setError,
  filter,
  startDate,
  endDate,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/allestablishments?page=${activePage}&&search=${searchQuery}&filter=${filter}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError(
          "An error occurred. Please check your network connection and try again."
        );
      }
      reject(error);
    }
  });
};

//estb  ticket mngment
export const HandleTicketMangEstb = ({ token, activePage, filter, role }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/getETicket?page=${activePage}&&filter=${filter}&&role=${role}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// estbuser
export const fetchUserEstb = async ({ token, uid }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/estabuser?uid=${uid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// estb estb details

export const fetchDetailsEstb = async ({ token, eid }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/viewEstablishment?eid=${eid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data[0]);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// get candidatw hired
export const hiredCandidate = async ({ token, eid, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/candidateshired?eid=${eid}&?page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// candindte job
export const candidateJob = async ({ token, jobId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/jobdetails?jobId=${jobId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// estb review

export const estbReview = async ({ token, eid, activePage }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/estabreviews?eid=${eid}&&?page=${activePage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// estb r3eport
export const estbReport = ({ role, token, activePage, searchQuery }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/reports?page=${activePage}&&search=${searchQuery}`,
        { role },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

export const GetMarkNoShow = ({ token, activePage, searchQuery }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/mark-no-show?page=${activePage}&&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
      // console.log("response",);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

/**************************************push notification-*************************************/

// create Notification
export const createNotification = async ({
  token,
  title,
  bodyData,
  selectedFile,
}) => {
  try {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", bodyData);
    if (selectedFile && typeof document === "object") {
      formData.append("document", selectedFile);
    }
    const response = await Axios.post(
      "/api/v1/admin/pushNotification",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating notification:", error);
    throw error.response?.data;
  }
};

// get Notification in table

export const getNotification = async ({
  token,
  activePage,
  searchQuery,
  filter,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/pushNotification?page=${activePage}&&search=${searchQuery}&filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};
// delet from tabl
export const deletNotification = async ({ id, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(`/api/v1/admin/pushNotification`, {
        data: { id },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
      console.log(`Error deleting user: ${error.message}`);
    }
  });
};
// Cancle Schedules Notification
export const CancleNotification = async ({ key, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/sendnotification`,
        { uniqueKey: key },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
      console.log(`Error deleting user: ${error.message}`);
    }
  });
};
// update notification table

export const updateNotification = async ({
  title,
  bodyData,
  selectedFile,
  token,
  id,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("id", id); // Include id in FormData
      formData.append("title", title);
      formData.append("body", bodyData);
      if (selectedFile) {
        formData.append("document", selectedFile);
      }

      const response = await Axios.patch(
        `/api/v1/admin/pushNotification`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
      console.log(`Error deleting user: ${error.message}`);
    }
  });
};

//globl api

export const notificationtToAny = async ({
  notificationId,
  type,
  ids,
  notificationType,
  notifyTime,
  notifyDate,
  timeZone,
  gid,
  token,
  isTemplate,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.post(
        `/api/v1/admin/sendnotification`,
        {
          notificationId,
          type,
          gid,
          ...(ids.length > 0 ? { ids } : {}),
          notificationType,
          notifyTime,
          notifyDate,
          timeZone,
          ...(isTemplate ? { isTemplate } : {}),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// get all grop usr

export const getAllUser = async ({ token, activePage, searchQuery }) => {
  // console.log("type",type);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/group?page=${activePage}&&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// crete group

export const createGroup = async ({ token, groupName, Users, selectImg }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("groupName", groupName);
      formData.append("Users", JSON.stringify(Users));
      formData.append("groupImg", selectImg);

      const response = await Axios.post(`/api/v1/admin/group`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// all user
export const getfechUser = async ({ token, searchQuery, activePage, gId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/getAllusers?page=${activePage}&&search=${searchQuery}&&gId=${
          gId || ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// grtopu details users

export const handleParticularGroupUsers = async ({
  token,
  searchQuery,
  activePage,
  gid,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(
        `/api/v1/admin/group_details?gid=${gid}&&page=${activePage}&&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// group user delet
export const deletGroupUser = async ({ token, id, gid, action }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/group`,
        { uid: id, id: gid, action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// group user add new user to existing group
export const addNewUserToGroup = async ({ token, ids, gid, action }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/group`,
        { uid: ids, id: gid, action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.log("error is coming", error);
      reject(error);
    }
  });
};

// ***************** Commission Data ************************************
export const getCommission = ({ token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/admin/commission`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

export const updateCommission = ({ commission, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/commission`,
        { commission },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

export const updateReferral = ({ refer, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.patch(
        `/api/v1/admin/referral`,
        { refer: refer },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      console.error("Error updating user:", error);
      reject(error);
    }
  });
};

export const postBlogs = ({ title, content, blogImg, metaData, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append("content", content);
      formdata.append("blogImg", blogImg);
      formdata.append("metaData", metaData);
      const response = await Axios.post(`/api/v1/admin/blog`, formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      resolve(response);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const deleteBlog = ({ _id, token }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.delete(`/api/v1/admin/blog`, {
        data: { _id: _id },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      resolve(response);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const getBlogs = ({ page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.get(`/api/v1/blog?page=${page}`);
      resolve(response.data);
    } catch (error) {
      reject(error.response.data);
    }
  });
};

export const FetchBlog = ({ bid }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/blog-details?bid=${bid}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

// *****************************************************mark no how***********************

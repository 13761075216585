import React, { useEffect, useState } from "react";
import TextEditor from "../../../components/CommonComp/TextEditor/TextEditor";

import "./AboutSection.css";
import Button3 from "../../../components/CommonComp/Button3/Button3";
import Button from "../../../components/CommonComp/Button/Button";
import { aboutUs, getAboutUs } from "../../../Api";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import Loader from "../../../components/CommonComp/Loader/Loader";
import Reactquil from "../../../components/CommonComp/Reactquill/Reactquil";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AboutSection = () => {
  const token = useSelector((state) => state.user.user.token);
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(true);

  // update about us
  const updateAbout = async () => {
    try {
      console.log(about);
      return
      const response = await aboutUs({ data: about, token });
      toast.success("About Section Updated");
    } catch (err) {
      toast.warn("Some error occured");
      console.log(err);
    }
  };

  // get aboutus
  const getAbout = async () => {
    try {
      const response = await getAboutUs({ token });
      setAbout(response.AboutSection);
      setLoading(false);
     
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAbout();
  }, []);

  const handleProcedureContentChange = (content) => {
    setAbout(content);
   
  };

  const breadcrumbItems = [
    <Link key="home" to="/" className='All-color-breakcurm'>Home</Link>,
    <Link key="contentAboutSection" to="/content-management" className='All-color-breakcurm'>&nbsp;Content Management</Link>,
    <span style={{fontWeight:'bolder'}}>&nbsp;About Section</span>
  ];

  return (
    <div className="aboutsection">
      <div className='d-flex justify-content-between align-items-center flex-wrap'>
          <div className='d-flex justify-content-start align-items-center flex-wrap me-5'>
          {breadcrumbItems.map((item, index) => (
                  <div  className="all-breakcrum"key={index}>
                    {item}
                    {index < breadcrumbItems.length - 1 && ' > '}
                  </div>
                ))}
            </div>
            </div>

      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: "40px" }}>
          {!loading ? (
            <>
              <Reactquil
                initialContent={about}
                onContentChange={handleProcedureContentChange}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="about-btn">
        <Button title="Save changes" onClick={updateAbout} />
      </div>
    </div>
  );
};

export default AboutSection;

import React, { useState } from "react";
import { postBlogs } from "../../../Api";
import { useSelector } from "react-redux";
import Reactquil from "../../../components/CommonComp/Reactquill/Reactquil";
import Button from "../../../components/CommonComp/Button/Button";
import { toast } from "react-toastify";

const AddBlog = ({ setaddblogvisible, addblogvisible, setChange, change }) => {
  const token = useSelector((state) => state.user.user.token);

  const [data, setdata] = useState({
    title: "",
    content: "",
    blogImg: null, // Store the raw file instead of base64 string
    metaData: {
      title: "",
      description: "",
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setdata((prevData) => ({ ...prevData, blogImg: file }));
    }
  };

  const CreateBlog = () => {
    postBlogs({
      title: data.title,
      content: data.content,
      blogImg: data.blogImg,
      metaData: JSON.stringify(data.metaData),
      token,
    })
      .then((res) => {
        toast.success(res?.message);
        setdata({ title: "", content: "", blogImg: "", metaData: "" });
        setaddblogvisible(!addblogvisible);
        setChange(!change);
      })
      .catch((err) => toast.error(err.message));
  };

  const onContentChange = (content) => {
    setdata({ ...data, content });
  };

  return (
    <div className="add-blog-container">
      <div className="blog-header">
        {!data.blogImg ? (
          <input
            type="file"
            id="blogImg"
            accept="image/*"
            name="blogImg"
            onChange={handleImageChange}
          />
        ) : (
          <img
            src={URL.createObjectURL(data.blogImg)}
            alt="Blog"
            style={{ height: "150px", borderRadius: "25px" }}
          />
        )}

        <input
          type="text"
          name="title"
          placeholder="Enter Blog Title"
          value={data.title}
          onChange={(e) => setdata({ ...data, title: e.target.value })}
          style={{
            marginLeft: "50px",
            width: "300px",
            height: "50px",
            borderRadius: "18px",
            border: "0",
            paddingLeft: "5px",
          }}
        />
      </div>

      <div className="blog-input" style={{ marginTop: "10px" }}>
        <Reactquil
          initialContent={data.content}
          onContentChange={onContentChange}
        />
      </div>

      <div className="meta-data" style={{ marginTop: "25px" }}>
        <textarea
          type="text"
          name="metaTitle"
          placeholder="Meta Title"
          value={data.metaData.title}
          style={{ width: "30%", marginRight: "10px" }}
          onChange={(e) =>
            setdata({
              ...data,
              metaData: { ...data.metaData, title: e.target.value },
            })
          }
        />
        <textarea
          type="text"
          name="metaDescription"
          placeholder="Meta Description"
          value={data.metaData.description}
          style={{ width: "30%" }}
          onChange={(e) =>
            setdata({
              ...data,
              metaData: { ...data.metaData, description: e.target.value },
            })
          }
        />
      </div>

      <div className="about-btn" onClick={CreateBlog}>
        <Button title="Add Blog" />
      </div>
    </div>
  );
};

export default AddBlog;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/CommonComp/Button/Button";
import { useSelector } from "react-redux";
import { CircularProgress, Modal } from "@mui/material";
import { deleteBlog, getBlogs, postBlogs } from "../../../Api";
import AddBlog from "./AddBlog";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Blogs.css";
import { toast } from "react-toastify";
const Blogs = () => {
  const token = useSelector((state) => state.user.user.token);
  const [addblogvisible, setaddblogvisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(true);
  const navigate = useNavigate();
  const [data, setdata] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const FetchBlogs = () => {
    getBlogs({ page: data.page })
      .then((result) => {
        let res = result;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setdata(x);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDelete = (_id) => {
    deleteBlog({ _id, token })
      .then((result) => {
        toast.success(result?.message);
        setChange(!change);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    FetchBlogs();
  }, [change]);

  const breadcrumbItems = [
    <Link key="home" to="/" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="contentPrivacyPolicy"
      to="/content-management"
      className="All-color-breakcurm">
      &nbsp;Content Management
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Blogs</span>,
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "20px",
        }}>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex justify-content-start align-items-center flex-wrap me-5">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>
        </div>
        <h1
          onClick={() => setaddblogvisible(!addblogvisible)}
          style={{ cursor: "pointer" }}>
          {addblogvisible ? <CloseIcon /> : "+"}
        </h1>
      </div>
      {!addblogvisible ? (
        <>
          {!loading ? (
            <div className="blogs-list">
              {data.history.length > 0 &&
                data.history.map((elem) => (
                  <div
                    key={elem._id}
                    className="blog-item"
                    style={{ position: "relative" }}
                    onClick={() =>
                      navigate(`/content-management/blogs/${elem._id}`)
                    }>
                    <DeleteIcon
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "8px",
                        color: "white",
                        cursor: "pointer",
                        zIndex: "6",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(elem._id);
                      }}
                    />
                    <img
                      loading="lazy"
                      className="blog-img"
                      src={`https://d28qcxi1juf339.cloudfront.net/${elem?.blogImg}`}
                      alt={elem.title}
                    />
                    <p className="blog-title">{elem.title}</p>
                  </div>
                ))}
            </div>
          ) : (
            <div className="loading-container">
              <CircularProgress />
              <h1>Loading</h1>
            </div>
          )}
        </>
      ) : (
        <AddBlog
          setaddblogvisible={setaddblogvisible}
          addblogvisible={addblogvisible}
          setChange={setChange}
          change={change}
        />
      )}
    </div>
  );
};

export default Blogs;

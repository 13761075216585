import React, { useEffect, useState } from 'react'
import './Blogs.css'
import style from "./contentWraper.module.css";
import { Link, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { Helmet } from 'react-helmet';
import { FetchBlog } from '../../../Api';

const BlogDetails = () => {
    const { bid } = useParams()
    const [data, blogData] = useState({
        title: "",
        content: "",
        blogImg: "",
        metaData: "",
    })
    const getBlogs = () => {
        FetchBlog({ bid }).then((result) => {
            const { title,
                content,
                blogImg,
                metaData } = result
            blogData({
                title,
                content,
                blogImg,
                metaData
            });
        }).catch((err) => {
            console.log(err);
        })
    }

    useEffect(() => {
        getBlogs()
    }, [])

    return (
        <>
            <div
                style={{
                    background: `url(/AboutBarrom/backgroundImg.png)`,
                    backgroundSize: "cover",
                }}>
                <header className={style.header}>
                    {/* <Helmet> */}
                    {/* <title>{data?.metaTags?.title}</title> */}
                    {/* <meta name="description" content={data?.metaTags?.description} /> */}
                    {/* </Helmet> */}
                </header>
                <Link to={"/"}>
                    <img
                        loading="lazy"
                        className={style.barrom}
                        src={"/LandingPageImages/Barrom.svg"}
                        alt=""
                    />
                </Link>

            </div>
            <div style={{ display: 'flex', }}>
                <p onClick={() => window.history.back()} style={{ marginLeft: '20px', alignContent: 'center' }}><ArrowBackIosNewIcon /></p>
                <h1 style={{ textAlign: 'center', marginLeft: '30px' }}>{data?.title}</h1>
            </div>
            <div className={style.middlePart}>
                <div
                    className={style.contentSection}
                    dangerouslySetInnerHTML={{ __html: data?.content }}></div>
            </div>

        </>
    )
}

export default BlogDetails